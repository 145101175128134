.header{
    padding-top: 40px; 
    background-color: black;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    font-family: var(--normal-font-family);
    font-size: 3.0rem;
    font-weight: 600;
    min-height: 320px;
    text-align: center;
    padding-bottom: 20px;
}

.weirdo{
    font-family: var(--weird-font-family);
    font-size: 1.6rem;
    font-weight: 500;
}

.header_subtitle{
    font-size: 1.2rem;
    font-weight: 500;
    padding: 2rem 0 0 0;
}

.header_button{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:20px;
}
