
.price{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    border: 1px solid #ffffff;
    color:white;
    font-family: var(--normal-font-family);
    padding: 4rem 2rem 2.5rem 2rem;
    border-radius: 80px;
    margin: 4rem auto;
}

.price h3{
    color:var(--primary-color);
    padding: 0 0 1rem 0;
}

.price p{
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
}

.pagamentos{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 400px;
    color:white;
    font-family: var(--normal-font-family);
    padding: 1rem 1rem;



}
.custom-mercadopago-button {
    width: 300px !important; /* Ajuste a largura do botão */
    height: 50px !important; /* Ajuste a altura do botão */
    font-size: 18px !important; /* Ajuste o tamanho do texto */
    background-color: #3483fa !important; /* Cor de fundo do botão */
    color: #fff !important; /* Cor do texto */
    border: none !important; /* Remover borda */
    border-radius: 25px !important; /* Bordas arredondadas */
    cursor: pointer !important; /* Cursor de ponteiro ao passar sobre o botão */
}

.custom-mercadopago-button:hover {
    background-color: #2968c8 !important; /* Cor de fundo ao passar o mouse */
}

.footer p{
    color:white;
    font-family: var(--normal-font-family);
    font-size: 13px;
    font-weight: 300;
    margin: 0;
    padding: 0 0 1rem 0;
    text-align: center;
}

.footer{
    padding: 4rem 0 2rem 0;
    max-width: 800px;
}

a{
    color:white;
    text-decoration: none;
}