.home{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    /* height: 100vh; */
    background-color: #f5f5f5;
    
}


