.about {
  font-family: (var(--normal-font-family));
   color:white;
   display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width:950px;
    /* padding: 4rem 2rem 2.5rem 2rem; */

  }
  
.aboutleft{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  color:white;
  font-family: var(--normal-font-family);
  /* padding: 4rem 2rem 0rem 2rem; */
  border-radius: 80px;
  margin: 4rem 1rem 1rem 1rem;
  gap:40px;
}

.aboutleft li{
  list-style-type: none;
  font-weight: 500;
  margin: 0 0 1rem 0;

}


/* create padding for mobile screens */
@media (max-width: 600px) {
  .about{
    
    width: 90%;
  }

  .aboutleft{
    flex-direction: column;
    gap: 20px;
  }
}