/* @import graphik from google */
@import url('https://fonts.googleapis.com/css2?family=Graphik:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Icons:wght@300;400;500;700&display=swap');
/* import font Shadows Into Light */
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');


:root {
  --normal-font-family: 'SF Pro Display', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica', Arial, sans-serif;
  --weird-font-family: 'SF Pro Display', sans-serif;
  --primary-color: #0071e3;
  --secondary-color: #c2fad9;
  background-color: black;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button.primary {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.7rem 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 40px;
  cursor: pointer;
  margin-top: 2rem;
  /* animate background color */
}

h3
{
  font-size: 1.2rem;
  font-weight: 500;
  margin:0;
  color:white;


}

button.secondary {
  background-color: transparent;
  color: var(--primary-color);
  border: var(--primary-color) solid 1px;
  padding: 0.7rem 1rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 40px;
  cursor: pointer;
  margin-top: 2rem;
  transition: background-color 0.3s;
 
}

button.secondary:hover {
  background-color: var(--primary-color);
  color: white;
}

