/* FormStyles.css */
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 20px;
    width: 400px;
    border-radius: 8px;

  }
  
  .form input, .form textarea {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    /* border: 2px solid #ddd; */
    border-radius: 4px;
    box-sizing: border-box; /* Inclui padding no cálculo da largura */
  }
  
  .form textarea {
    height: 100px;
    resize: none; /* Impede o usuário de alterar o tamanho da textarea */
    font-family: var(--normal-font-family) !important;
  }
  
  .form button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .form button:hover {
    background-color: #0056b3;
  }
  