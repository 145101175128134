.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    color:white;
}

.main_title{
 padding: 3rem 1rem 1rem 1rem;
 text-align: center;
}

.main_content {
    width: 100%;
    max-width: 1200px; /* Ajuste conforme necessário */
    margin: 0 auto;
}

.dica{
    font-size: 14px;
    font-weight: 300;
    /* italic */
    font-style: italic;

}

.react-compare-image {
    /* width: 100%;  */
   height: 300px;
}

.curved-arrow {
    margin-left: 10px; /* Ajuste conforme necessário */
    vertical-align: middle; /* Ajuste conforme necessário */
    font-size: 24px; /* Ajuste conforme necessário */
    color: #3498db; /* Ajuste conforme necessário */
}

.FAQ{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    background-color: #000000;
    color:white;
    font-family: var(--normal-font-family);
    padding: 2rem 0 5rem 0;
}

.FAQ_item{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid #ffffff;
    cursor: pointer;

}
.question{
    font-size: 1.2rem;
    font-weight: 500;
    margin: 0;
    color:white;
}

.watermark {
    position: absolute;
    top: 50%;  /* Centraliza verticalmente */
    left: 50%; /* Centraliza horizontalmente */
    transform: translate(-50%, -50%);
    color: white;
    font-family: var(--normal-font-family);
    font-size: 80px;
    text-align: center;
    opacity: 0.5;  /* Torna a marca d'água parcialmente transparente */
    z-index: 10;  /* Garante que a marca d'água fique sobre a imagem */
    pointer-events: none;  /* Permite clicar na imagem abaixo da marca d'água */
}

/* create padding for mobile screens */
@media (max-width: 600px) {
    .FAQ{
      
      width: 90%;
    }
  
  }