.navbar{
    padding: 0;
    margin: 0;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    position: fixed;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
    background-color: rgba(35, 35, 35, 0.8);
    font-family: var(--normal-font-family);
    font-size: 0.8rem;
    font-weight: 500;
}



.menu_items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:20px;

}

.menu_items a{
    text-decoration: none;
    color: white;
}